import { isBefore, startOfDay, startOfYear, format, isYesterday } from 'date-fns'

export default (date: string, today: boolean = false) => {
  const currentDate = new Date()
  const incomingDate = new Date(date)
  if (isBefore(startOfDay(incomingDate), startOfDay(currentDate))) {
    if (isBefore(startOfYear(incomingDate), startOfYear(currentDate))) {
      return format(incomingDate, 'd.MM.yyyy')
    }
    if (isYesterday(incomingDate)) {
      return 'Yesterday'
    }
    return format(incomingDate, 'd MMM')
  }
  return today ? 'Today' : format(incomingDate, 'HH:mm')
}
